import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./Login.css";
import jeddah_second_health_cluster_logo from "../jeddah_second_health_cluster.jpeg";
import logo from "../../D_small.png";
import moh_logo from "../moh.jpg";
import React, { useState } from "react";
const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  const loginCallback = (data) => {
    if (data.loginStatus === "loggedIn") {
      console.log("Hello !");
      // window.location.replace(window.location.href);
      window.location.replace("/flights");
    } else {
      alert(data.loginStatus);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(username);
    const response = await fetch("/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    });
    const data = await response.json();
    // console.log(data);
    loginCallback(data);
    setMessage(data.message);
  };
  return (
    <div className="container-fluid px-1 px-md-5 px-lg-1 px-xl-5 py-5 mx-auto">
      <div className="card card0 border-0">
        <div className="row d-flex">
          <div className="col-lg-6">
            <div className="card1 pb-5">
              <div className="row px-3 justify-content-center mt-4 mb-5 border-line">
                <img
                  src={jeddah_second_health_cluster_logo}
                  className="image"
                  alt="jeddah_second_health_cluster_logo"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card2 card border-0 px-4 py-5">
              <h1 className="login_form_title">
                نظام الرصد الصحى الاستباقى بالمنافذ
              </h1>
              <div className="row px-3 mb-4">
                <div className="col-4 line"></div>
                <div className=" col-4 ">
                  <img src={moh_logo} className="moh_logo " alt="moh_logo" />
                </div>
                <div className=" col-4 line"></div>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="row px-3">
                  <label className="mb-1">
                    <h6 className="mb-0 text-sm">Username</h6>
                  </label>
                  <input
                    className="mb-4"
                    type="text"
                    name="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Enter username"
                  />
                </div>
                <div className="row px-3">
                  <label className="mb-1">
                    <h6 className="mb-0 text-sm">Password</h6>
                  </label>
                  <input
                    type="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter password"
                  />
                </div>

                <div className="row mb-3 px-3">
                  <button type="submit" className="btn btn-blue text-center">
                    Login
                  </button>
                </div>
              </form>
              {message && <p>{message}</p>}
            </div>
          </div>
        </div>
        <div className="bg-blue py-4">
          <div className="row px-3">
            <div className="col-1 dedalus-imag">
              <img
                src={logo}
                className="app-dedaluslogo"
                alt="Dedauls"
                style={{ width: "18px", height: "20px" }}
              />
            </div>
            <div className="col" style={{ paddingLeft: "0" }}>
              <small className="mb-2">
                Copyright © Dedalus. All rights reserved.
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
