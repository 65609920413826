import logo from "./D_small.png";
import "./App.css";
import Login from "./components/Login/Login";
import UploadExcel from "./components/UploadExcel";

function App() {
  return <Login />;
}

export default App;
